import { useEffect } from 'react'
import { useDialogVisibility, useScreenType } from '../../../../hooks'
import { useTranslate } from '../../../../localization'
import { Button } from '../../../button/button'
import { SepaMandateValidationDialog } from './SepaMandateValidationDialog'

export const IbanSubmitButton = function IbanSubmitButton({
  isDisabled,
  isLoading,
  onSubmit,
  ibanCustomerData,
}: {
  isDisabled: boolean
  isLoading: boolean
  onSubmit: () => void
  ibanCustomerData: { firstName: string; lastName: string; iban: string }
}): JSX.Element {
  const translate = useTranslate()
  const screenVariant = useScreenType()
  const {
    hide: hideSepaMandateValidationDialog,
    show: showSepaMandateValidationDialog,
    visible: isSepaMandateValidationDialogVisible,
  } = useDialogVisibility('sepa-mandate-validation')

  useEffect(() => {
    if (!ibanCustomerData?.iban || ibanCustomerData.iban === '') {
      hideSepaMandateValidationDialog()
    }
  }, [hideSepaMandateValidationDialog, ibanCustomerData?.iban])

  return (
    <>
      <Button
        onClick={showSepaMandateValidationDialog}
        disabled={isDisabled}
        isLoading={isLoading}
        size={screenVariant === 'desktop' ? 'medium' : 'large'}
        fullWidth={screenVariant !== 'desktop'}
      >
        {translate('validate')}
      </Button>
      {isSepaMandateValidationDialogVisible && ibanCustomerData && onSubmit ? (
        <SepaMandateValidationDialog
          onHide={hideSepaMandateValidationDialog}
          onSubmit={onSubmit}
          isLoading={isLoading}
          firstName={ibanCustomerData.firstName}
          iban={ibanCustomerData.iban}
          lastName={ibanCustomerData.lastName}
        />
      ) : (
        <></>
      )}
    </>
  )
}
