import { useScreenType } from '../../../../hooks'
import { useTranslate } from '../../../../localization'
import { Button } from '../../../button/button'

export const GenericPaymentMethodSubmitButton = function GenericPaymentMethodSubmitButton({
  isDisabled,
  isLoading,
}: {
  isDisabled: boolean
  isLoading: boolean
}): JSX.Element {
  const translate = useTranslate()
  const screenVariant = useScreenType()

  return (
    <Button
      type="submit"
      className="next-button"
      size={screenVariant === 'desktop' ? 'medium' : 'large'}
      fullWidth={screenVariant !== 'desktop'}
      disabled={isDisabled}
      isLoading={isLoading}
    >
      {translate('pay_and_finalize_subscription')}
    </Button>
  )
}
