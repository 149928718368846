import type { FinishSubscriptionProblem } from '@orus.eu/backend/src/services/checkout-service'
import { ensureError, TechnicalError } from '@orus.eu/error'
import { TemporaryProblemError, useCrash } from '@orus.eu/pharaoh'
import { useNavigate, useParams, type NavigateOptions } from '@tanstack/react-router'
import { memo, useEffect, useMemo, useState, type FunctionComponent } from 'react'
import { trpc } from '../../../../client'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { usePermissions } from '../../../../lib/use-permissions'
import WrongLoggedInUserMessage from '../wrong-logged-in-user-message'
import { waitForContractToBeReady } from './checkout-util'
import { FinalizingSubscriptionLoadingState } from './finalizing-subscription-loading-state'
import SharedSubscriptionCheckoutSignatureErrorPage from './shared-subscription-checkout-signature-error-page'

type SharedSubscriptionCheckoutValidationPageProps = {
  subscriptionId: string
  token: string
}

const SharedSubscriptionCheckoutValidationPage: FunctionComponent<SharedSubscriptionCheckoutValidationPageProps> = memo(
  function SharedSubscriptionCheckoutValidationPage({
    subscriptionId,
    token,
  }: SharedSubscriptionCheckoutValidationPageProps): JSX.Element {
    const crash = useCrash()
    const navigateToOnboarding = useNavigateTo({ to: '/home', hash: 'onboarding' })
    const navigate = useNavigate()
    const isPlatform = usePermissions().type === 'platform'
    const isPartner = usePermissions().type === 'partner'
    const [checkoutError, setCheckoutError] = useState<FinishSubscriptionProblem | undefined>(undefined)
    const params = useParams({ strict: false })

    const contractRoute = useMemo<NavigateOptions>(() => {
      if (isPartner) {
        const { organization } = params
        if (!organization) throw new Error('Missing organization')
        return {
          to: '/partner/$organization/contracts/$subscriptionId',
          params: { subscriptionId, organization },
        }
      }

      return { to: '/bak/contracts/$subscriptionId', params: { subscriptionId } }
    }, [isPartner, params, subscriptionId])

    useEffect(() => {
      let cancelled = false

      ;(async () => {
        const checkoutResult = await trpc.subscriptions.finishSubscription.mutate({
          subscriptionId,
          token,
        })

        if (cancelled) return

        if (checkoutResult.type === 'failure') {
          setCheckoutError(checkoutResult.problem)
          return
        }

        await waitForContractToBeReady(subscriptionId)
        if (cancelled) return

        if (isPlatform || isPartner) void navigate(contractRoute)
        else navigateToOnboarding()
      })().catch((err: unknown) => {
        if (cancelled) return

        crash(ensureError(err))
      })

      return () => {
        cancelled = true
      }
    }, [crash, navigateToOnboarding, subscriptionId, token, navigate, contractRoute, isPartner, isPlatform])

    if (checkoutError) {
      switch (checkoutError.type) {
        case 'session':
          return <WrongLoggedInUserMessage />
        case 'checkout-init':
          crash(new TechnicalError('Checkout init failed'))
          return <></>
        case 'temporary-api-failure':
          crash(new TemporaryProblemError())
          return <></>
        case 'no-payment-method':
          crash(new TechnicalError('No payment method'))
          return <></>
        case 'signature':
          return <SharedSubscriptionCheckoutSignatureErrorPage />
      }
    } else {
      return <FinalizingSubscriptionLoadingState />
    }
  },
)
export default SharedSubscriptionCheckoutValidationPage
